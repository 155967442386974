import axios from 'axios';
import {AntdNotification} from "@/utils/toast";
// 不能使用useRouter ,useRoute，他们需要在setup中调用执行后才能用
import router from '@/router'
import {downloadFile, getSignDataArr, getToken} from "@/utils/common";


// 创建 Axios 实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API + '/api/admin',
    timeout: process.env.VUE_APP_TIMEOUT // 超时时间
});
// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        // 在请求发送之前可以进行一些处理，例如设置 token、添加请求头等
        const token = getToken()
        config.headers['Client'] = 'browser'
        if (token) {
            // 让每个请求都携带token
            config.headers['Authorization'] = token
        }
        if (config.method === 'post' || config.method === 'put') {
            config.headers['Content-Type'] = 'application/json;charset=utf-8'
        }
        // 签名内容
        let signDataArr = getSignDataArr()
        signDataArr.forEach((item) => {
            config.headers[item.key] = item.value
        })
        config.headers.authorization = token

        if (config.baseURL.indexOf('dev') !== -1 || config.baseURL.indexOf('127.0.0.1') !== -1 || config.baseURL.indexOf('localhost') !== -1 || config.baseURL.indexOf('test') !== -1) {
            // 非正式环境下，输出请求信息
            let data = config.data ? config.data : config.params
            let url = config.url.replace(config.baseURL, '')
            console.log('发送请求:' + url, data)
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// 响应拦截器
instance.interceptors.response.use(
    (response) => {
        // 在接收到响应数据之前可以进行一些处理，例如解析响应数据、错误处理等
        // ...
        const contentType = response.headers['content-type'];
        if (contentType === 'application/octet-stream' ||
            contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            // 注意：Blob类型文件下载需要请求头参数添加 responseType:'blob'  下载 导出等功能需要
            const filename = response.headers['x-filename'];
            downloadFile(response.data, filename)

        } else {
            const res = response.data
            if (response.config.baseURL.indexOf('dev') !== -1 || response.config.baseURL.indexOf('127.0.0.1') !== -1 || response.config.baseURL.indexOf('localhost') !== -1 || response.config.baseURL.indexOf('test') !== -1) {
                // 非正式环境下，输入请求信息
                let url = response.config.url.replace(response.config.baseURL, '')
                console.log('服务器响应:' + url, res)
            }
            return res;
            // 响应数据是有效的 JSON 格式，继续处理
            // return Promise.resolve(response.data);
        }
    },
    (error) => {
        // 统一处理错误
        return handleRequestError(error)
    }
);

// 统一处理错误
const handleRequestError = (error) => {
    // 进行错误处理
    if (error.response) {
        // 服务器响应错误
        let status = error.response.status
        // 在这里可以进行错误处理逻辑，例如弹出错误提示、记录错误日志等
        switch (status) {
            case 400:
                console.error('参数校验失败:', error.response.data.message);
                AntdNotification.error(error.response.data.message || '参数校验失败')
                return Promise.reject(error.response.data.message ?? '参数json解析失败');
            case 401:
                console.error('未授权:', error.response.data.message);
                if (error.response.data.message === 'Token不存在或已过期'){
                    router.replace('/login')
                    AntdNotification.error('账号已过期,请重新登录')
                    return Promise.reject({error: 'Unauthorized', message: '账号已过期,请重新登录'});
                }else {
                    AntdNotification.error(error.response.data.message || '账号已过期,请重新登录')
                    return Promise.reject({error: '401', message: error.response.data.message});
                }

            case 404:
                console.error('404:', error.response.data.message);
                AntdNotification.error(error.response.data.message || '资源不存在')
                return Promise.reject({error: '接口不存在', message: error.response.data.message});
            case 500:
                console.error('服务器内部错误:', error.response.data.message);
                AntdNotification.error(error.response.data.message || '服务器内部错误')
                return Promise.reject({error: '服务器内部错误', message: error.response.data.message});
            default:
                AntdNotification.error('服务器响应错误')
                console.error('服务器响应错误:', error.response.data);
        }

    } else if (error.request) {
        // 请求未收到响应
        console.error('请求未收到响应:', error.request);
        AntdNotification.error('请求未收到响应')
        // 在这里可以进行错误处理逻辑，例如弹出错误提示、记录错误日志等
    } else {
        // 请求配置出错
        console.error('请求配置出错:', error.message);
        AntdNotification.error('请求配置出错')
        // 在这里可以进行错误处理逻辑，例如弹出错误提示、记录错误日志等
    }
}


// 封装请求方法
class AxiosService {
    constructor() {
        if (AxiosService.instance) {
            return AxiosService.instance;
        }
        AxiosService.instance = this;
    }

    // GET 请求
    get(url, params = null) {
        if (params && params.sort_status) {
            params.sort_status = params.sort_status.replace('ing', '')+'ing'
        }
        return instance.request({
            method: 'get',
            url,
            params,
        });
    }

    // POST 请求
    post(url, data = null, params = null, responseType) {
        return instance.request({
            method: 'post',
            url,
            data,
            params,
            responseType
        });
    }

    // PUT 请求
    put(url, data = null, params = null) {
        return instance.request({
            method: 'put',
            url,
            data,
            params,
        });
    }

    // PATCH 请求
    patch(url, data = null, params = null) {
        return instance.request({
            method: 'patch',
            url,
            data,
            params,
        });
    }

    // DELETE 请求
    delete(url, params = null) {
        return instance.request({
            method: 'delete',
            url,
            params,
        });
    }

}

// 创建 AxiosService 实例
const axiosService = new AxiosService();

// 导出实例化后的 AxiosService 对象
export default axiosService;
